/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useMediaQuery, Box, Grid, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import CAIHCard from "../CAIHCard";
import CAIHPrediceError from "../CAIHPrediceError";
import TextSkeleton from "../TextSkeleton";
import ConsentKnowMore from "./ConsentKnowMore";
import ModalUtil from "../../utils/ModalUtil";

function ConsentGathering(props) {
  const { t } = useTranslation();
  const [selectedConsents, setSelectedConsents] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const setModal = ModalUtil.useModal()[1];
  const {
    isLoading,
    validateConsents,
    newConsents,
    refusedConsents,
    completedConsents,
    changeConsentFunc,
    acceptEverythingFunc,
    refuseEverythingFunc,
    originalyUnavailableConsents,
  } = props;

  const getKnowMore = consent => {
    setModal({
      isOpen: true,
      title: t("consents:consentGathering.knowMore"),
      content: (
        <ConsentKnowMore
          title={consent.intentLabel}
          description={consent.intentDescription}
        />
      ),
      backdropReason: "click",
    });
  };

  const handleChange = (newConsent, type) => {
    if (!selectedConsents.includes(newConsent)) {
      setSelectedConsents([...selectedConsents, newConsent]);
    }

    changeConsentFunc(newConsent, type);
  };

  return (
    <CAIHCard hasPadding={false} cardContentNoPadding>
      {isLoading ? (
        <Box p={4}>
          <TextSkeleton lines={4} width={isMobile ? "100" : "70"} />
        </Box>
      ) : (
        <Box>
          <Box
            p={4}
            sx={{ borderBottom: 1, borderBottomColor: "cobalt.grey10" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Box mr={1}>
                <Button
                  onClick={() => acceptEverythingFunc()}
                  variant="CAIHLite"
                  startIcon={<CobaltIcon name="check" />}
                >
                  {t("consents:consentGathering.acceptAll")}
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => refuseEverythingFunc()}
                  variant="CAIHLite"
                  startIcon={<CobaltIcon name="x" />}
                >
                  {t("consents:consentGathering.refuseAll")}
                </Button>
              </Box>
            </Grid>
          </Box>
          {newConsents.map(newConsent => (
            <Box
              key={newConsent.intentId}
              sx={{
                paddingLeft: "32px",
                paddingTop: "20px",
                paddingBottom: "20px",
                paddingRight: "32px",
                "&:not(:last-child)": {
                  borderBottom: 1,
                  borderBottomColor: "cobalt.grey10",
                },
              }}
            >
              {newConsent.status === "MAINCARE_READ_ERROR" &&
                newConsent.intentCode === "PREDICE" && (
                  <Grid item xs={12} sm={6} md={7} lg={8}>
                    <CAIHPrediceError />
                  </Grid>
                )}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6} md={7} lg={8}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: originalyUnavailableConsents.includes(newConsent)
                        ? "cobalt.lynch"
                        : "cobalt.ultramarine",
                    }}
                  >
                    {newConsent.intentLabel}
                  </Typography>
                  {newConsent.intentDescription !== null &&
                    newConsent.intentDescription !== "" &&
                    (originalyUnavailableConsents.includes(newConsent) ? (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "cobalt.lynch",
                          textDecoration: "underline",
                        }}
                      >
                        {t("consents:consentGathering.knowMore")}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        onClick={() => getKnowMore(newConsent)}
                        sx={{
                          color: "cobalt.bleuDigital100",
                          cursor: "pointer",
                        }}
                      >
                        {t("consents:consentGathering.knowMore")}
                      </Typography>
                    ))}
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={4}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Button
                      disabled={originalyUnavailableConsents.includes(
                        newConsent,
                      )}
                      className={
                        completedConsents.includes(newConsent) &&
                        validateConsents.includes(newConsent) &&
                        "selected"
                      }
                      variant="CAIHConsentButton"
                      onClick={() => handleChange(newConsent, "COMPLETED")}
                    >
                      {t("common:yes")}
                    </Button>
                    <Button
                      variant="CAIHConsentButton"
                      disabled={originalyUnavailableConsents.includes(
                        newConsent,
                      )}
                      className={
                        refusedConsents.includes(newConsent) &&
                        validateConsents.includes(newConsent) &&
                        "refused"
                      }
                      onClick={() => handleChange(newConsent, "REFUSED")}
                    >
                      {t("common:no")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      )}
    </CAIHCard>
  );
}

ConsentGathering.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  newConsents: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      intentId: PropTypes.string.isRequired,
      patientId: PropTypes.string.isRequired,
      intentLabel: PropTypes.string.isRequired,
      intentDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
  refusedConsents: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      intentId: PropTypes.string.isRequired,
      patientId: PropTypes.string.isRequired,
      intentLabel: PropTypes.string.isRequired,
      intentDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
  completedConsents: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      intentId: PropTypes.string.isRequired,
      patientId: PropTypes.string.isRequired,
      intentLabel: PropTypes.string.isRequired,
      intentDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
  validateConsents: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      intentId: PropTypes.string.isRequired,
      patientId: PropTypes.string.isRequired,
      intentLabel: PropTypes.string.isRequired,
      intentDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
  originalyUnavailableConsents: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      intentId: PropTypes.string.isRequired,
      patientId: PropTypes.string.isRequired,
      intentLabel: PropTypes.string.isRequired,
      intentDescription: PropTypes.string.isRequired,
    }),
  ).isRequired,
  changeConsentFunc: PropTypes.func.isRequired,
  acceptEverythingFunc: PropTypes.func,
  refuseEverythingFunc: PropTypes.func,
};

ConsentGathering.defaultProps = {
  acceptEverythingFunc: null,
  refuseEverythingFunc: null,
};

export default ConsentGathering;
