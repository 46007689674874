import React from "react";
import { useMediaQuery, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

const CAIHPrediceError = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        py: 1,
        pr: 3,
        pl: 6,
        mr: "10px",
        alignSelf: "flex-start",
        display: "flex",
        alignItems: "center",
        backgroundColor: "cobalt.bleuDigital05",
        borderRadius: "4px",
        color: "cobalt.ultramarine",
        position: "relative",
        marginBottom: "10px",
      }}
    >
      <Typography
        sx={{
          fontSize: isMobile ? "12px" : "14px",
          lineHeight: "12px",
        }}
      >
        Problème lors de la récupération du statut depuis la plateforme Prédice.
      </Typography>
      <CobaltIcon
        name="info"
        style={{
          color: "cobalt.ultramarine",
          position: "absolute",
          fontSize: isMobile ? 16 : 18,
          left: 16,
        }}
      />
    </Box>
  );
};

export default CAIHPrediceError;
